#phase,
#remaining {
	display: inline;
}

.warn {
	color: red;
}

.pomodoro {
	color: white;
}

.break {
	color: rgb(8, 230, 8);
}

.completed {
	color: yellow;
}
